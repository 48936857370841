////////////////////////////////////////////////
// DARKLY
////////////////////////////////////////////////
$grey-lighter: #fdf6e3;
$grey-light: #eee8d5;
$grey: #839496;
$grey-alt: #586e75;
$grey-dark: #073642;
$grey-darker: #002b36;

$orange: #cb4b16;
$yellow: #b58900;
$green: #859900;
$cyan: #2aa198;
$blue: #268bd2;
$purple: #6c71c4;
$red: #d33682;

$black-bis: rgb(18, 18, 18);

$primary: $cyan !default;
$warning: $orange;
$info: $blue;
$yellow-invert: #fff;

$light: $grey-lighter;
$dark: darken($grey-darker, 3);

$family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-monospace: "Inconsolata", "Consolas", "Monaco", monospace;

$radius-small: 3px;
$radius: 0.4em;
$radius-large: 8px;

$body-size: 15px;
$size-7: 0.85em;

$title-weight: 500;
$subtitle-weight: 400;
$subtitle-color: $grey-dark;

$border-width: 2px;
$border: $grey-dark;

$body-background-color: $grey-darker;
$background: $grey-dark;
$footer-background-color: $background;

$title-color: $grey;
$subtitle-color: $grey-alt;
$subtitle-strong-color: $grey-light;

$text: $grey;
$text-light: lighten($text, 10);
$text-strong: darken($text, 5);

$box-shadow: none;
$box-background-color: $grey-dark;

$card-shadow: none;
$card-background-color: $grey-darker;
$card-header-box-shadow: none;
$card-header-background-color: rgba($black-bis, 0.2);
$card-footer-background-color: rgba($black-bis, 0.2);

$link: $yellow;
$link-hover: $grey-light;
$link-hover-border: $grey-light;
$link-focus: darken($grey-light, 10);
$link-focus-border: $grey-light;
$link-active: darken($grey-light, 10);

$button-color: $grey;
$button-background-color: $background;
$button-border-color: $grey;

$input-color: $grey;
$input-icon-color: darken($grey-light, 10);
$input-background-color: $grey-lighter;
$input-border-color: darken($grey-light, 10);
$input-hover-color: $grey-light;
$input-disabled-background-color: $grey-dark;
$input-disabled-border-color: $grey-dark;

$table-color: $text;
$table-head-color: $grey-lighter;
$table-background-color: $grey-dark;
$table-cell-border: 1px solid $grey;

$table-row-hover-background-color: $grey-darker;
$table-striped-row-even-background-color: $grey-darker;
$table-striped-row-even-hover-background-color: lighten($grey-darker, 4);

$pagination-border-color: $border;

$navbar-background-color: $grey-dark;
$navbar-item-color: $grey;
$navbar-item-hover-color: $grey-lighter;
$navbar-item-active-color: $grey-lighter;
$navbar-item-active-background-color: transparent;
$navbar-item-hover-background-color: transparent;
$navbar-dropdown-border-top: 1px solid $grey-darker;
$navbar-divider-background-color: $grey-darker;
$navbar-dropdown-arrow: #fff;
$navbar-dropdown-background-color: $grey-dark;
$navbar-dropdown-item-hover-color: $grey-lighter;
$navbar-dropdown-item-hover-background-color: lighten($grey-darker, 4);
$navbar-dropdown-item-active-background-color: lighten($grey-darker, 4);
$navbar-dropdown-item-active-color: $grey-lighter;

$dropdown-content-background-color: $background;
$dropdown-item-color: $text;
$dropdown-item-hover-color: $link-hover;

$bulmaswatch-import-font: true !default;
