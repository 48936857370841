@use 'sass:color' as color;

@import 'bulmaswatch/solar/variables';

$white: white;

// Improve text contrast for accessibility
$-lightness-change: 36%;
$text: color.scale($text, $lightness: $-lightness-change);
$title-color: color.scale($title-color, $lightness: $-lightness-change);
$subtitle-color: $title-color;
$code: #ffc8e2;

$discordBlurple: #5865F2;
$twitchPurple: #9146FF;
$googleWhite: #000000;

$input-placeholder-color: #9d8484;
//$input-background-color: $white;

//$table-colors

.is-warning {
  background-color: $warning !important;
}

@import 'bulma/bulma';
@import 'bulmaswatch/solar/overrides';
@import '@duncte123/bulma-tagsinput/src/sass/index';
@import './elements';

@import 'bulma-extensions/dist/css/bulma-extensions.min.css';
@import 'bulma-extensions/bulma-tooltip/src/sass/index';
@import 'node_modules/@wizishop/ng-wizi-bulma/ng-wizi-bulma';
// Manually copied in the file, it was not exported
@import "./assets/themes/ng-pick-datetime-picker.min.css";
@import "flag-icon-css/css/flag-icons.min.css";
@import "vis-timeline/dist/vis-timeline-graph2d.min.css";


// set the colours from the dark mode ourselves

:root {
  --color-note: #2f81f7;
  --color-tip: #3fb950;
  --color-warning: #d29922;
  --color-severe: #db6d28;
  --color-caution: #f85149;
  --color-important: #a371f7;
}

@import 'markdown-it-github-alerts/styles/github-base.css';
