/**
 * Global styles for the site
 */

@use './assets/themes/default' as theme;

* {
  // Controls how far stuff is apart from each other.
  // Matches the Bulma box padding so that we can have consistent spacing
  // We're using CSS variables over SASS variables so it can be changed on the fly as needed
  --spacing: 20px;

  // For best effect, manually enable this
  // cursor: url("./assets/tony_cursor_fixed.png"), auto !important;
}

// <editor-fold desc="v2 default layout" defaultstate="collapsed">

html {
  font-family:
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.default-layout-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  overflow-y: auto;

  > * {
    width: 100%;
  }

  .default-layout-header,
  .default-layout-footer {
    flex: 0;
  }

  .default-layout-main {
    flex: 1;
    padding: 1em;
  }
}

// </editor-fold>

a {
  color: inherit;
  text-decoration: underline;

  &:hover:not(.no-hover) {
    color: theme.$link-hover !important;

    &.menu-item-link:not(.is-active) {
      background-color: inherit;
    }
  }
}

.navbar-item,
.navbar-link {
  color: inherit;
}

.title {
  word-break: normal;
  // Line break is kinda like word break, but for languages without spaces, like Chinese, Japanese, or Korean
  line-break: normal;
}

hr {
  border-block-start: 2px solid;
}

.is-centered:not([hidden]) {
  display: flex;
  justify-content: center;
}

//.markdown-container {
//  *:not(a) {
//    color: theme.$text;
//  }
//}


.tags-input.is-filter input{
  color: theme.$text !important;
}

#twitch-embed {
  iframe {
    width: 100% !important;
    height: 480px !important;
  }
}

.is-absolute {
  position: absolute !important;
}

.clearfix {
  clear: both;
}

.vis-timeline, .vis-timeline * {
  border-color: theme.$border !important;
  color: theme.$text !important;
}

.vis-item {
  background-color: theme.$primary;
}

.vis-timeline .vis-even {
  background-color: theme.$table-striped-row-even-background-color;
}

.table td.is-emphasized, .table th.is-emphasized, .table tr.is-emphasized {
  background-color: rgba(theme.$primary, 0.3) !important;
  color: #fff
}

$bar_height: 5px;

div.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $bar_height;
  overflow: hidden;
  background-color: theme.$grey-light;
  z-index: 100;

  &:before {
    display: block;
    position: absolute;
    content: " ";
    left: -200px;
    width: 200px;
    height: $bar_height;
    background-color: theme.$primary;
    animation: loading 2s linear infinite;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  to {
    left: 100%;
  }
}

/* Special case for mobile navbar items that need to hide */
.navbar-item.mob {
  &.has-dropdown {
    .navbar-dropdown {
      display: none;
    }
  }

  &.is-active {
    .navbar-dropdown {
      display: block;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

strong {
  color: #eafdff;
}
